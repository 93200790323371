import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Persona from "./routes/persona";
import React from "react";
//import {createStyles, makeStyles} from "@mui/styles";
//import {Theme} from "@mui/material";

const App = () => {

/* 
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            appRoot: {
                height: "100%",
                width: "100%",
                padding: "0",
                margin: "0",
                position: "relative",
            },
        })
    );

    const classes = useStyles(); */


    return (
    <Router>
      <Routes>
      <Route path="/" element={<Navigate replace to="/missmoorestyle" />} />
        <Route path={"/:displayName"} element={<Persona />}>
        </Route>

      </Routes>
    </Router>
  );
};

export default App;
