import {createTheme} from "@material-ui/core";
import BodyGrotesqueFitBold from './fonts/Body-Grotesque-Fit-Bold.otf';
import BodyGrotesqueFitBoldItalic from './fonts/Body-Grotesque-Fit-Bold-Italic.ttf';
import FoundersGroteskRegular from './fonts/FoundersGrotesk-Regular.ttf';
import FoundersGroteskRegularItalic from './fonts/FoundersGrotesk-RegularItalic.ttf';

const bodyGrotesqueFitBold = {
    fontFamily: 'BodyGrotesqueFitBold',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: '600',
    src: `
   local('BodyGrotesqueFitBold'),
   local('Body-Grotesque-Fit-Bold'),
   url(${BodyGrotesqueFitBold}) format('opentype')
 `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const bodyGrotesqueFitBoldItalic = {
    fontFamily: 'BodyGrotesqueFitBoldItalic',
    fontDisplay: 'swap',
    fontWeight: '600',
    src: `
   local('BodyGrotesqueFitBoldItalic'),
   local('Body-Grotesque-Fit-Bold-Italic'),
   url(${BodyGrotesqueFitBoldItalic}) format('truetype')
 `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const foundersGroteskRegular = {
    fontFamily: 'FoundersGrotesk-Regular',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: '600',
    src: `
   local('FoundersGroteskRegular'),
   local('FoundersGrotesk-Regular'),
   url(${FoundersGroteskRegular}) format('truetype')
 `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const foundersGroteskRegularItalic = {
    fontFamily: 'FoundersGrotesk-RegularItalic',    
    fontDisplay: 'swap',
    fontWeight: '600',
    src: `
   local('FoundersGroteskRegularItalic'),
   local('FoundersGrotesk-RegularItalic'),
   url(${FoundersGroteskRegularItalic}) format('truetype')
 `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};


const theme: {
    [key: string]: any;
} = {
    "@global": {
        body: {
            margin: 0,
            padding: 0,
        },
    },
    typography: {
        fontFamily: [
            "BodyGrotesqueFitBold, normal",
            "BodyGrotesqueFitBoldItalic, italic",
            "FoundersGrotesk-Regular, normal",
            "FoundersGrotesk-RegularItalic, italic",
        ].join(','),
        fontSize: 16,
        fontColor: "black",

        h1: {
            fontSize: "1.75rem",
            opacity: .86,
            fontFamily: 'BodyGrotesqueFitBold'
        },
        h2: {
            fontSize: "1.25rem ",
            opacity: .65,
            fontFamily: 'FoundersGrotesk-Regular'
        },
        h3: {
            fontSize: "1rem",
            color: "black",
            fontFamily: 'BodyGrotesqueFitBoldItalic',
            textTransform: "Capitalize",
        },
        h4: {
            fontSize: "1.25rem",
            color: "black",
            fontFamily: 'BodyGrotesqueFitBold',
            textTransform: "Capitalize",
            opacity: .86

        },
        h5: {
            fontSize: "1.125rem",
            opacity: .86,
            color: "black",
            fontFamily: 'BodyGrotesqueFitBold'
        },
        h6: {
            fontSize: "1.125rem",
            color: "#0048FF",
            fontFamily: 'BodyGrotesqueFitBoldItalic',
            textTransform: "lowercase",
            textDecoration: "underline",


        },
        p: {
            fontSize: "1.25rem ",
            opacity: .65,
            fontFamily: 'FoundersGrotesk-Regular'
        },
        caption: {
            fontSize: "1rem",
            color: "black",
            opacity: .65,
            fontFamily: 'FoundersGrotesk-RegularItalic',
            textTransform: "Capitalize",
        }
    },
    components: {
        Multitypography: {
            defaultProps: {
                fontFamily: [bodyGrotesqueFitBoldItalic, bodyGrotesqueFitBold, foundersGroteskRegular, foundersGroteskRegularItalic]
            }
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [bodyGrotesqueFitBold, bodyGrotesqueFitBoldItalic, foundersGroteskRegular, foundersGroteskRegularItalic],
            }
        },
        
    },
};

export default createTheme(theme);
