import React, {useState, useLayoutEffect} from "react";
import Navigation from "../components/Navigation";
import {Container, CssBaseline} from "@material-ui/core";
import EndorsedBrands from "../components/EndorsedBrands";
import Embeds from "../components/Embeds";
import axios from "axios";
import {useParams} from "react-router-dom";
import usePersonAnalytics from "../components/ProfileAnalytics";
import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";
import BrandyLogo from '../images/FullBrandyLogo.svg'
import Grid from "@material-ui/core/Grid";

export default function Persona() {
    const [loading, setLoading] = useState(true);
    const [data, setData]: any = useState(null);
    let {displayName} = useParams();

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            appRoot: {
                height: "100%",
                width: "100%",
                padding: 0,
                margin: "auto",
                background: "white",
            },
            brandyLogo: {
                height: "40px",
                align: "center",
                alignSelf: "center",
                justifySelf: "center !important",
                marginTop: theme.spacing(6),
                marginBottom: theme.spacing(2),
                opacity: ".95"
            }
        })
    );

    const classes = useStyles();    

    usePersonAnalytics(loading);
    
    useLayoutEffect(() => {
        if (loading === false) {
            return;
        } 

        const getData = async () => {
            await axios
                .get(
                    `https://brandy-personas-api.herokuapp.com/v1/profile/${displayName}`
                )
                .then((response) => {
                    setLoading(false);
                    setData(response.data);
                    console.log(response.data);
                    if(response.data.displayName){
                        document.title= `${response.data.displayName} | Brandy Personas`
                    }
                    
                })
                .catch((error) => {
                    setLoading(false);
                    setData(null);
                    console.log(error);
                });
        };
        getData();           
    },[loading,displayName]);

    if (loading)
        return (
            <Container maxWidth="sm">
                <CssBaseline/>
                <div>Loading...</div>
            </Container>
        );

    if (!data)
        return (
            <Container maxWidth="sm">
                <CssBaseline/>
                <div>Error Profile For {displayName} Not Found</div>
            </Container>
        );

    return (
        <React.Fragment>
            <CssBaseline/>
            <Container maxWidth="sm" className={classes.appRoot}>
                <Navigation
                    avatarUrl={data.avatarUrl}
                    displayName={data.displayName}
                    websiteUrl={data.websiteUrl}
                    tagline={data.tagline}
                    backgroundColor={data.backgroundColor}
                    socialLinks={data.socialLinks}
                    shortcutButtons={data.shortcutButtons}
                />
                <EndorsedBrands endorsedBrands={data.userEndorsedBrands}/>
                <Grid spacing={4} container justifyContent="center" alignItems="center">
                    <Embeds content={data.embeddedContent}/>
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                    <a href="https://itsbrandy.io">
                        <img src={BrandyLogo} alt="itsbrandy.io" className={classes.brandyLogo}/>
                    </a>
                </Grid>
            </Container>
        </React.Fragment>
    );
}
