import "swiper/swiper-bundle.min.css";
import React, {FC, useState} from "react";
import {Container, Typography, Grid} from "@material-ui/core";
import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";
import {IEndorsedBrand, IBrand, IImpactBadge} from "../types";
import SwiperCore, {EffectCoverflow, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react/swiper-react";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
//import Box from "@material-ui/core/Box";


SwiperCore.use([EffectCoverflow, Pagination]);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        endorsedBrandsSectionRoot: {
            padding: theme.spacing(8, 0, 4, 0),
            minWidth: "100%",
            minHeight: "100%",
            width: "100%",
            height: "100%",
            justifyContent: "start"
        },
        intro: {
            justifySelf: "start",
            paddingLeft: theme.spacing(4)
        },
        swiper: {
            padding: theme.spacing(0, 4, 2, 4),
            overflowY: "hidden",
            minHeight: "100%",
            minWidth: "100%",
            width: "100%",
            height: "100%"
        },
        endorsedBrandAdContainer: {
            padding: theme.spacing(2, 0),
            cornerRadius: "8px"
        },
        brandHeaderContainer: {
            margin: theme.spacing(0, 0, 0, 0),
            padding: 0,
        },
        logoContainer: {
            padding: theme.spacing(0)
        },
        brandLogo: {
            backgroundColor: "white",
            height: "72px",
            width: "72px",
            boxShadow: theme.shadows[16],
        },
        adVideo: {
            padding: 0,
            margin: 0
        },
        adRightColumnHeader: {
            marginStart: "16px"
        },
        brandName: {
            marginBottom: "8px"
        },
        impactBadgeContainer: {
            width: "100%",
            minWidth: "100%",
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            gap: "0 16px",
            padding: "0"
        },
        impactBadgeDefault: {
            borderRadius: "50%",
            backgroundColor: "black",
            opacity: ".65",
            height: "24px",
            width: "24px"
        },
        supplyChainEthicsBadge: {
            borderRadius: "50%",
            backgroundColor: "black",
            height: "24px",
            width: "24px"
        },
        impactBadgeSelected: {
            borderRadius: "50%",
            backgroundColor: "#73B52E",
            height: "24px",
            width: "24px"
        },
        adGraphicCard: {
            margin: theme.spacing(2, 0, 0, 0),
            borderRadius: "5px",
            backgroundColor: "white",
            position: "relative",

        },
        badgeInfoOverlay: {
            margin: 0,
            padding: theme.spacing(6, 6),
            borderRadius: "5px",
            backgroundColor: "white",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: "0px",
            left: "0px",
            opacity: .95,
            visible: false,
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center"
        },
        largeIcon: {
            borderRadius: "50%",
            backgroundColor: "black",
            height: "48px",
            width: "48px"
        },
        largeIconSelected: {
            borderRadius: "50%",
            backgroundColor: "#73B52E",
            height: "48px",
            width: "48px"
        },
        altLogoContainer: {
            display: "flex",
            justifyContent: "center",
        },
        altLogo: {
            display: "inline-block",
            height: "72px",
            width: "72px",
            aspectRatio: "1/1",
            backgroundColor: "blue",
            margin: theme.spacing(3, 0, 1, 0),
            borderRadius: "50%",
            marginBottom: theme.spacing(1),
        },
        optionText: {
            marginBottom: theme.spacing(2),
            fontSize: "0.85rem",
        },
        optionTagline: {
            fontSize: "0.75rem",
            marginBottom: theme.spacing(6),
        },
        ctaBtn: {
            margin: theme.spacing(2, 0, 1, 0),
            display: "flex",
            padding: theme.spacing(1.5, 2),
            justifyContent: "space-between",
            boxShadow: theme.shadows[16],
            width: "100%",
            borderRadius: "3.5px",
            backgroundColor: "white",
            textDecoration: "none"
        },
        adCopy: {
            fontColor: "black",
            opacity: 1,
            fontSize: '1.125rem',
            margin: theme.spacing(1, 2, 0, 2),
        },
        personalizedEndorsement: {
            marginTop: theme.spacing(1),
            margin: theme.spacing(1, 2, 2, 3)
        },

    })
);

interface Props {
    endorsedBrands: IEndorsedBrand;
}

const Brands: FC<Props> = props => {
    const classes = useStyles();

    const getAdContent = (brand: IBrand) => {
        if (brand.ad.videoUrl !== "" && brand.ad.videoUrl) {
            return (
                <video  width="100%" height="100%" autoPlay muted loop playsInline className={classes.adVideo}>
                    <source src={brand.ad.videoUrl} type="video/mp4"/>
                    Your browser does not support the video tag.
                    </video>
            )
        } else {
            return (
                <CardMedia
                    component="image"
                    height="100%"
                    width="100%"
                    src={brand.ad.imageUrl}
                />
            )
        }
    };


    const BrandSlide = (brand: IBrand) => {

        const [overlayOpen, toggleOverlay] = useState(false);
        const [selectedBadge, setSelectedBadge] = useState({} as IImpactBadge);

        // Badge types (from API):
        // 1. "Supply Chain Ethics"
        // 2. "Sustainability"
        // 3. "Underrepresented Founders"
        // 4. "Contribution to Human Well-being"
        // 5. "Cruelty-Free"
        // 6. "Helping Communities with Disabilities"
        // 7. "Equity and Inclusion"

        function saveBadgeState(clickedBadge: IImpactBadge) {
            console.log("clickedBadge: ", clickedBadge.name);
            toggleOverlay(overlayOpen => !overlayOpen);
            setSelectedBadge(clickedBadge);
        }


/*         function getBadgeClass() {

            if (!overlayOpen) {
                return classes.impactBadgeDefault;
            } else {
                switch (selectedBadge.name) {
                    case "Supply Chain Ethics Badge":
                        return classes.supplyChainEthicsBadge;
                    // case "Sustainability":
                    //     return classes.sustainabilityBadge;
                    // case "Underrepresented Founders":
                    //     return classes.underrepresentedFoundersBadge;
                    // case "Contribution to Human Well-being":
                    //     return classes.humanWellbeingBadge;
                    // case "Cruelty-Free":
                    //     return classes.crueltyFreeBadge;
                    // case "Helping Communities with Disabilities":
                    //     return classes.disabiledCommunitiesBadge;
                    // case "Equity and Inclusion":
                    //     return classes.equityAndInclusionBadge;
                    default:
                        return "black";
                }
            }
        } */


        // function getAvatarBgColor(overlayOpen: boolean, badge: IImpactBadge) {
        //     if (!overlayOpen) {
        //         return "black";
        //     } else {
        //         switch (badge.name) {
        //             case "Supply Chain Ethics":
        //                 return theme.palette.icons.yellow;
        //             case "Sustainability":
        //                 return theme.palette.icons.green;
        //             case "Underrepresented Founders":
        //                 return theme.palette.icons.purple;
        //             case "Contribution to Human Well-being":
        //                 return theme.palette.icons.red;
        //             case "Cruelty-Free":
        //                 return theme.palette.icons.orange;
        //             case "Helping Communities with Disabilities":
        //                 return theme.palette.icons.blue;
        //             case "Equity and Inclusion":
        //                 return theme.palette.icons.pink;
        //             default:
        //                 return "black";
        //         }
        //     }
        // }


        return (
            <Grid className={classes.endorsedBrandAdContainer}>
                <Grid container className={classes.brandHeaderContainer}>
                    <Avatar className={classes.brandLogo} src={brand.logoUrl}/>
                    <Grid
                        item
                        xs={8}
                        container
                        direction='column'
                        justifyContent='center'
                        className={classes.adRightColumnHeader}>
                        <Typography className={classes.brandName} variant='h5'>{brand.brandName}</Typography>
                        {/*Make this dynamic to badges from API response*/}
                        <Container className={classes.impactBadgeContainer}>
                            {brand.ad.badges.map(badge => {
                                    // let pathToBadge = badgeImages(`require("../images/impact_badges/${badge.name}.svg`); TODO: remove hardcoded impact badge and swap with this line
                                    const pathToBadge = require("../images/impact_badges/AccessibilitySupportBadge.svg")
                                    return (
                                        <Avatar
                                            className={overlayOpen ? classes.impactBadgeSelected : classes.impactBadgeDefault}
                                            key={badge.id}
                                            onClick={() => saveBadgeState(badge)}
                                            src={pathToBadge.toString()}
                                        />
                                    )
                                }
                            )}
                        </Container>
                    </Grid>
                </Grid>

                <Card className={classes.adGraphicCard} elevation={16} z-index={0}>

                    <Grid container>{getAdContent(brand)}</Grid>

                    {overlayOpen && (
                        <Grid className={classes.badgeInfoOverlay} container>
                            <Avatar className={overlayOpen ? classes.largeIconSelected : classes.largeIcon}
                                    src={(require("../images/impact_badges/AccessibilitySupportBadge.svg").toString())}/>
                            <Typography align='center' variant='h6'>{selectedBadge.name}</Typography>
                            <Typography align='center' variant='body2'>{selectedBadge.description}</Typography>
                            <Typography align='center' variant='body2'>{selectedBadge.cta} on our <span><a
                                href={selectedBadge.url}>about page</a></span></Typography>
                        </Grid>
                    )}
                </Card>

                {/*TODO: Add a badge footer here*/}
                <Button className={classes.ctaBtn} style={{textDecoration: 'none'}} href={brand.ad.ctaUrl}>
                    <Typography variant='h3' component='span'>{brand.ad.ctaPrompt}</Typography>
                    <Typography variant='h3' component='span'>&#62;</Typography>
                </Button>
                <Grid container>
                    <Typography
                        align='left'
                        variant='h2'
                        noWrap={false}
                        className={classes.adCopy}>
                        {brand.ad.description}

                    </Typography>
                    <Typography
                        align='right'
                        variant='caption'
                        className={classes.personalizedEndorsement}>
                        {/*{{brand.ad.personalizedEndorsement}}*/}
                        {/*todo: remove hardcoded personaliedEndorsement*/}
                        {"\"The MOST comfortable Parisian-style dresses.\" - Jessica"}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container justifyContent="flex-start" direction="row"  className={classes.endorsedBrandsSectionRoot}>
            <Typography component='h4' variant='h4' align='center' className={classes.intro}>
                {props.endorsedBrands.brandIntro}:
            </Typography>
            <Swiper className={classes.swiper}
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: false,
                    }}
                    pagination={true}
            >
                {props.endorsedBrands.brands.map(brand => (
                    <SwiperSlide key={brand.id}>{BrandSlide(brand)}</SwiperSlide>
                ))}
            </Swiper>
        </Grid>
    );
};

export default Brands;
