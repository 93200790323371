import Grid from "@material-ui/core/Grid";
import { FC } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { IEmbed } from "../types";

interface Props {
    content: IEmbed[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        embedContainer: {
            justifyContent: "center",
            margin:  theme.spacing(0,4),
            padding: 0,
        },
        embed: {
            minWidth: "100%",
            minHeight: "315px",
            border: "none",
            borderRadius: "8px",
            boxShadow: theme.shadows[16],
            allowFullScreen: true,
            padding: theme.spacing(0),
            margin: theme.spacing(0)
        },
        instagram: {
            // height: "60vh",
        },
        twitter: {
            // height: "70vh",
        },
    })
);

const Embeds: FC<Props> = props => {
    const classes = useStyles();

    const getEmbeds = () => {
        const res: any = [];
        props.content.forEach((embed: IEmbed) => {
            if (!embed.enabled) return;
            if (embed.socialNetwork.toLowerCase() === "youtube") {
                res.push(
                        <iframe id={embed.contentId} key={embed.id}
                            title='yt-embed'
                            src={`https://www.youtube-nocookie.com/embed/${embed.contentId}`}
                            className={classes.embed}
                            allow="autoplay; encrypted-media; picture-in-picture" allowFullScreen>
                        </iframe>

                );
            } else if (embed.socialNetwork.toLowerCase() === "twitter") {
                res.push(
                        <iframe key={embed.id}
                            title='tweet-embed'
                            src='https://twitter.com/SumaaaaiL/status/1452320597531373575'
                            className={`${classes.embed} ${classes.twitter}}`}
                        ></iframe>
                );
            } else if (embed.socialNetwork.toLowerCase() === "instagram") {
                res.push(
                        <iframe  key={embed.id}
                            title='insta-embed'
                            src='https://www.instagram.com/p/B_7VZBfjKj7/'
                            className={`${classes.embed} ${classes.instagram}}`}
                        ></iframe>
                );
            }
        });
        return res;
    };

    return (
            <Grid container className={classes.embedContainer} justifyContent="center" >{getEmbeds()}</Grid>
    );
};

export default Embeds;
