import React, {FC} from "react";
import {Container, Typography, Box, Avatar} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {IButton, ISocialLink} from "../types";
import {Stack} from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        navRoot: {
            position: "relative",
            paddingX: theme.spacing(100)
        },
        coloredHeader: {
            height: "104px",
            width: "100%",
            padding: "0",
            margin: "0",
            top: "0px",
            left: "0px",
        },
        nav: {
            marginTop: theme.spacing(6),
            display: "flex-column",
        },
        avatar: {
            width: "100px",
            height: "100px",
            backgroundSize: "cover",
            marginLeft: "auto",
            marginRight: "auto",
            top: theme.spacing(6),
            borderRadius: "50%",
            boxShadow: "6px 6px 17px 2px rgba(0, 0, 0, 0.1)",  //custom boxShadow here

        },
        displayName: {
            paddingTop: theme.spacing(3),
        },
        tagline: {
            opacity: ".56",
            color: "black",
            marginTop: theme.spacing(.5),
            padding: 0
        },
        socialIconsContainer: {
            marginTop: theme.spacing(6),
            padding: 0,
            display: "flex",
            justifyContent: "center",
            gap: theme.spacing(2),
            wrap: "wrap"
        },
        socialIcon: {
            marginTop: theme.spacing(1),
            width: "24px",
            display: "flex",
            opacity: ".65"
        },
        websiteLink: {
            marginTop: theme.spacing(1),
            marginBottom: 0,
            marginX: 0,
            padding: 0,
            overflowWrap: "break-word"
        },
        buttonsContainer: {
            marginTop: theme.spacing(8),
            marginBottom: 0,
            marginX: theme.spacing(4)
        },
        shortcutButton: {
            width: "100%",
            textAlign: "center",
            boxShadow: "6px 6px 17px 2px rgba(0, 0, 0, 0.1)",  //custom boxShadow here
            borderRadius: "12px",
            cursor: "pointer",
            fontSize: "1rem",
            textDecoration: "none"
        },
        buttonText: {
            padding: theme.spacing(2)
        }
    })
);

interface Props {
    backgroundColor: string;
    displayName: string;
    avatarUrl: string;
    websiteUrl: string;
    tagline: string;
    socialLinks: ISocialLink[];
    shortcutButtons: IButton[];
}

const Navigation: FC<Props> = props => {
    const classes = useStyles();

    const getButtons = () => {
        const buttons: any = [];
        props.shortcutButtons.forEach(btn => {
            if (btn.enabled)
                buttons.push(
                    <a className={classes.shortcutButton} href={btn.url}  key={btn.id}>
                        <Typography className={classes.buttonText} component="h3" variant="h3">
                        {btn.description}</Typography></a>
                );
        });
        return buttons;
    };

    return (
        <Container className={classes.navRoot} key="root-container">
            <Box position="absolute" className={classes.coloredHeader} sx={{bgcolor: props.backgroundColor}}/>
            <Avatar alt={props.displayName} className={classes.avatar} src={props.avatarUrl}/>
            <Container className={classes.nav}>
                <Typography variant='h1' component='h1' align='center' className={classes.displayName}>
                    {props.displayName}
                </Typography>
                <Typography variant='h2' component='h2' align='center' className={classes.tagline}>
                    {props.tagline}
                </Typography>

                <Box className={classes.socialIconsContainer} pt={1}>
                    {props.socialLinks.map(social => {
                            return (
                                <Box key={social.socialNetworkId}>
                                    <a href={social.url} target='_blank' rel="noreferrer">
                                        <img className={classes.socialIcon} id={social.id} src={social.icon}
                                             alt={social.platform}/>
                                    </a>
                                </Box>

                            )
                        }
                    )}
                </Box>
                <Typography variant='h6' component='h6' align='center' className={classes.websiteLink}>
                    <a href={props.websiteUrl} target='_blank' rel="noreferrer">
                        {props.websiteUrl}
                    </a>
                </Typography>
                <Stack spacing={1.5} className={classes.buttonsContainer}>{getButtons()}</Stack>
            </Container>
        </Container>
    );
};

export default Navigation;
