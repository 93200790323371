import {useEffect} from 'react'

const usePersonAnalytics = async (loading: boolean)=>  {
useEffect(() =>{
  if (loading === false) return;
  let scriptEl = (function (w, d:Document) {

    let a = d.createElement('script')
    a.async = true;
    a.src = `${process.env.PUBLIC_URL}/analytics/profile.js`;
    d.head.appendChild(a)
    return a
  })(window, document);
  
  return () =>{
    document.head.removeChild(scriptEl);
  }
  /* eslint-enable */
},[loading])
  
}

export default usePersonAnalytics
